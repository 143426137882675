<template>
  <div class="mb-4">
      <CRow class="mb-0">
        <CCol lg="3">
          <CInput size="sm" v-model="company_name" placeholder="Company Name"/>
        </CCol>
        <CCol lg="3">
          <CInput size="sm" v-model="email" placeholder="Email"/>
        </CCol>
        <CCol lg="3">
          <CInput size="sm" v-model="contact_person" placeholder="Contact Person"/>
        </CCol>
        <CCol lg="1">
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton size="sm" block color="info" @click="search"><i class="icon-magnifier"></i> Search</CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol lg="3"> 
        </CCol>
        <CCol lg="3"> 
        </CCol>
        <CCol lg="3"> 
        </CCol>
        <CCol lg="1">
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton size="sm" block @click="clearSearch" color="info">Clear Search</CButton>
        </CCol>
      </CRow>
  </div>
</template>

<script>
  import config from '../../config.js';
  import axios from '../../axios';
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css';
    export default {
        name: "BookingTypeSearch",
        data() {
          return {
            company_name: "",
            email: "",
            contact_person: "", 
          }
        }, 
      components: {vSelect},
      methods: {
        search() {
          const data = {
            company_name: this.company_name,
            email: this.email,
            contact_person: this.contact_person, 
          }
          this.$emit("depot-search-query", data)
        },
        clearSearch() {
          this.company_name = "";
          this.email = "";
          this.contact_person = ""; 
          const data = {
            company_name: this.company_name,
            email: this.email,
            contact_person: this.contact_person, 
          }
          this.$emit("depot-search-query", data)
        }, 
      }
    }
</script>

<style scoped>

</style>
