<template>
	<div>
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
					<h5>List of Companies ({{dataList.total}})</h5>
			</CCol>
	     		 <CCol lg="6">
					<CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        <CButton 
					 v-if="config.getPermission('company').create"
					 size="sm" style="margin-right: 10px" 
					 color="success" @click="toggleModal()" 
					 class="float-lg-right">
					 <i class="fa fa-plus"></i>Add Company</CButton>

		      		<!-- <CButtonGroup size="sm" class="float-lg-right">
			          <CButton @click="toggleModal()" color="success"><i class="fa fa-plus"></i> Add Personnel</CButton>&nbsp;&nbsp;&nbsp;
			          <CButton :class="showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info">Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        </CButtonGroup>  -->
			    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @depot-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<hr>

		<CRow>			
	     
		      <CCol lg="12">  
				   	<div class="table-responsive-sm table-responsive-md"	>
	      				<CCol lg="12" class="text-center" v-if="isLoading"> 
						 	 <div class="spinner-border m-5" role="status">
							 <span class="sr-only">Loading...</span>
							</div>
						</CCol>
				      	<CDataTable
						  	v-else
					      	:items="dataList.data"
					      	:fields="fields"
							hover
							striped
							border
							small
							fixed
							
					    >   
					      	<template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
						            >
						            	<CDropdownItem v-if="config.getPermission('company').view" @click="viewModal(item)">View</CDropdownItem> 
						                <CDropdownItem v-if="config.getPermission('company').update" @click="updateModal(item)">Edit</CDropdownItem>
						                <CDropdownItem v-if="config.getPermission('company').delete" @click="deleteRow(item)">Delete</CDropdownItem> 
						            </CDropdown>
						        </td>
					      	</template>
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPage"
				          	:pages="Math.ceil(dataList.total / 20)"
				          	:activePage="currentPage"
				          	@update:activePage="updatePage"
				        />
					</div>
			  </CCol>
	    </CRow>
		
	    <CModal :title="title"  :show.sync="formModal" color="info" size="lg">
			
           	<form @submit.prevent="submit">
			 <CCard class="p-4">
           		<CRow class="mb-2">
					<CCol lg="12">
						<h6>Company Details</h6>
						<hr class="mt-0">
					</CCol> 
					
				</CRow>
           		<CRow>
					<CCol lg="6">
						<label>Company Name <span class="text-danger">*</span></label>
						<CInput
		                  label=""
		                  placeholder="Company name"
		                  v-model="dataParams.company_name"
		                  required="true"
		                />
					</CCol>
					<CCol lg="6">
						<label>Email Address <span class="text-danger">*</span></label>
						<CInput
		                  label=""
		                  placeholder="Enter email"
		                  v-model="dataParams.email"
		                  required="true"
		                  type="email"
		                />
					</CCol>
					
				</CRow>
				<CRow>
					<CCol lg="6">
						<label>Contact Number <span class="text-danger">*</span></label>
						<CInput
		                  placeholder="Enter contact number"
		                  v-model="dataParams.contact_number"
		                  required="true"
		                />
					</CCol>
					<CCol lg="6">
						<label>Contact Person <span class="text-danger">*</span></label>
						<CInput
		                  placeholder="Enter contact person"
		                  v-model="dataParams.contact_person"
		                  required="true"
		                />
					</CCol>
				</CRow>
				 <CRow>
					<CCol lg="6">
						<label>Address <span class="text-danger">*</span></label>
						<CInput
		                  placeholder="Enter address"
		                  v-model="dataParams.address"
		                  required="true"
		                />
					</CCol>
					<CCol lg="6">
						<label>Telephone Number <span class="text-danger">*</span></label>
						<CInput
		                  placeholder="Enter telephone number"
		                  v-model="dataParams.telephone_number"
		                  required="true"
		                />
					</CCol>
				</CRow>
				<CRow>
					<CCol lg="6">
						<label>Company Type</label>
						<v-select 
				            label="setting_name" 
				            :options="[
				            	{setting_name: 'Trucking with Agency'},
				            	{setting_name: 'Construction'},
								{setting_name: 'Trucking with No Agency'}
				            ]" 
				            :reduce="item => item.setting_name"
				            v-model="dataParams.company_type"
				            placeholder="Company Type"
				        > 
				        </v-select>  
					</CCol>
				</CRow>
				 
			</CCard> 
	           <div slot="footer" class="w-100">
				    <CButton type="submit" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 float-right">
	                   Save
	                </CButton>
	            	<CButton style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right" @click="formModal = false">
	                    Cancel
	                </CButton>
	               
	                
	        </div>
           	</form>			   
           	<div slot="footer" class="w-100"></div>
			</CModal>
	
		<!-- VIEWING DETAILS -->
        <CModal title="Company Details" :show.sync="showViewModal" color="info" size="lg"> 
        	<div class="pl-3 pr-3 pb-3"> 
				
				<CCard class="p-4 pl-2 pr-2 ml-2 mr-2 mt-0" > 
        		<CRow class="mb-2">
	        		<CCol lg="4">
	        			Company Name:
	        		</CCol>
	        		<CCol lg="8">
	        			{{dataParams.company_name_label}}
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Email:
	        		</CCol>
	        		<CCol lg="8">
	        			{{dataParams.email_label}}
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Contact Person:
	        		</CCol>
	        		<CCol lg="8">
	        			{{dataParams.contact_person_label}}
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Contact Number:
	        		</CCol>
	        		<CCol lg="8">
	        			{{dataParams.contact_number_label}}
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Address:
	        		</CCol>
	        		<CCol lg="8">
	        			{{dataParams.address_label}}
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Telephone Number:
	        		</CCol>
	        		<CCol lg="8">
	        			{{dataParams.telephone_number_label}}
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Company Type:
	        		</CCol>
	        		<CCol lg="8">
	        			{{dataParams.company_type ? dataParams.company_type : "N/A"}}
	        		</CCol>
	        	</CRow>
				 
				</CCard>
        	</div>
        	<div slot="footer" class="w-100">
            	<CButton style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right" @click="showViewModal = false">
                    Close
                </CButton> 
                
            </div>
        	<div slot="footer" class="w-100"></div>
        </CModal>
		</CCard>
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import moment from 'moment';

export default {
	mounted(){  
		this.getData(); 
		// this.getVendor();
		// this.getDepot();
		// this.getPersonnelType();

	},
	data(){
		return{
			config,
			options:'',
			title:'',
			isLoading: false,
			formModal: false,
			showViewModal: false,
			dataListAll: [],
			dataList: [],
			dataListInactive: [],
			depotName: "",
			dataParams: {
				company_name: "",
				contact_number: "",
				email: "",
				contact_person: "",
				address: "",
				telephone_number: "",
				company_type: ""
			},
			fields: [
				{
					key: 'company_name_label', 
					label: 'Company Name'
				},
				
				{
					key: 'company_type', 
					label: 'Company Type'
				},
				{
					key: 'email_label', 
					label: 'Email Address'
				},
				{
					key: 'contact_person_label', 
					label: 'Contact Person'
				},
				{
					key: 'contact_number_label', 
					label: 'Contact Number'
				},
				{
					key: 'address_label', 
					label: 'Address'
				},
				{
					key: 'telephone_number_label', 
					label: 'Telephone Number'
				},
				{
					key: 'action',
					label: 'Action'
				}
			],
			currentPageAll: 1,
			currentPage: 1,
			currentPageInactive: 1,
			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filter: {
				company_name: "",
				email: "",
				contact_person: "", 
			},
			// vendorList: {
			// 	data: []
			// },
			depotList: {
				data:[]
			},
			personnelTypeList: {
				data: []
			}
			
		}
	},
	name: 'Tables',
	components: {Search, Datepicker, vSelect},
	methods: {
		 getBadge (status) {
    	return status === 'active' ? 'success'
             : status === 'inactive' ? 'danger' : 'primary'
    },
		updatePage(data){
			this.currentPage = data;
			this.getData();
		},  

	    toggleModal(){
			this.title = "Add Company";
	    	this.formModal = !this.formModal;
			
	    },

	    submit(){
	    	var ax = {}; 
	    	var params = { ...this.dataParams };
	    	if(params.company_type == ""){
	    		params.company_type = null;
	    	}
	    	if(this.editMode){
	    		ax = axios.put(config.api_path+"/company/"+this.selectedRow.id, params)
	    	}
	    	else{
	    		ax = axios.post(config.api_path+"/company", params)
	    	}
	    	ax.then(response => {
	    		var text = 'Company successfully added!';
	    		if(this.editMode){
	    			text = 'Company successfully updated!';
	    		}
	    		Swal.fire({
					title: 'Success!',
					text,
					icon: 'success', 
				})
	    		this.formModal = false
	    		this.editMode = false;
	    		this.dataParams = {
					company_name: "",
					contact_number: "",
					email: "",
					contact_person: "",
					address: "",
					telephone_number: "",
					company_type: ""
				}
	    		this.getData(); 
	    	})
	    },

	    search(event){
	    	this.filter = event; 
	    	this.getData(); 
	    },
		  


	    getData(){ 
	   		this.isLoading = true; 
	    	axios.get(config.api_path+'/company?contact_person='+this.filter.contact_person+'&company_name='+this.filter.company_name+'&email='+this.filter.email+'&page='+this.currentPage+'&limit=20')
	    	.then(response => {
	    		this.dataList = response.data; 
	    		this.dataList.data = this.dataList.data.map((value, index)=>{
	    			value.company_name_label = value.company_name;
	    			if(value.company_name_label == "" || value.company_name_label == null){
	    				value.company_name_label = "N/A";
	    			}
	    			
	    			value.email_label = value.email;
	    			if(value.email_label == "" || value.email_label == null){
	    				value.email_label = "N/A";
	    			}

	    			value.contact_person_label = value.contact_person;
	    			if(value.contact_person_label == "" || value.contact_person_label == null){
	    				value.contact_person_label = "N/A";
	    			}

	    			value.contact_number_label = value.contact_number;
	    			if(value.contact_number_label == "" || value.contact_number_label == null){
	    				value.contact_number_label = "N/A";
	    			}

	    			value.address_label = value.address;
	    			if(value.caddresslabel == "" || value.address_label == null){
	    				value.address_label = "N/A";
	    			}

	    			value.telephone_number_label = value.telephone_number;
	    			if(value.telephone_number_label == "" || value.telephone_number_label == null){
	    				value.telephone_number_label = "N/A";
	    			}

	    			return value;
	    		}); 
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },

	    

	    updateModal(item){
			this.title = "Update Personnel";
	    	this.selectedRow = item;
	    	this.dataParams = item;
	    	this.formModal = true;
	    	this.editMode = true;
			

	    },

	    viewModal(item){

	    	this.selectedRow = item;
	    	this.dataParams = item;
	    	this.showViewModal = true; 
			

	    },

	    deleteRow(item){
	    	Swal.fire({
				icon:'warning'
,				title: 'Are you sure you want to delete this company?', 
				showCancelButton: true,
				confirmButtonText: `Yes`, 
			}).then((result) => { 
				if (result.isConfirmed) {
					axios.delete(config.api_path+'/company/'+item.id)
			    	.then(response => {
			    		this.getData(); 
			    		Swal.fire({
							title: 'Success!',
							text: "Company successfully deleted",
							icon: 'success', 
						})
			    	}) 
				}
			})  
	    },
 
 

 	}
}
</script>
